import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyCBdFJiQzItfLAaqiLqbTF0VJ-PakPGbLg",
  authDomain: "everchat-1850a.firebaseapp.com",
  databaseURL: "https://everchat-1850a.firebaseio.com",
  projectId: "everchat-1850a",
  storageBucket: "everchat-1850a.appspot.com",
  messagingSenderId: "461444478178"
};
firebase.initializeApp(config);
firebase.firestore().settings( { timestampsInSnapshots: true })

export default firebase;
