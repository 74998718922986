import React from "react";
import ReactDOM from "react-dom";

import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Privacy from "./components/Privacy";
import Home from "./components/Home";
import Chat from "./components/Chat";
import Spinner from "./Spinner";
import registerServiceWorker from "./registerServiceWorker";
import firebase from "./firebase";
import "semantic-ui-css/semantic.min.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider, connect } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import { setUser, clearUser } from "./actions";

import ReactGA from "react-ga";
ReactGA.initialize("UA-161401864-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer,
  // applyMiddleware(thunkMiddleware, loggerMiddleware),
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

class Root extends React.Component {
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // console.log(user);
        this.props.setUser(user);
        if (window.location.pathname == "/") {
          this.props.history.push("/lobby");
        }

        this.props.history.push(window.location.pathname);
      } else {
        const time = Math.round(new Date().getTime() / 1000);
        const user = {
          uid: time,
          displayName: "Guest-" + time,
          photoURL: "https://ui-avatars.com/api/?name=anonymous" + time,
          guest: true,
        };
        this.props.setUser(user);
        // this.props.history.push("/");
        // this.props.clearUser();
      }
    });
  }

  render() {
    return this.props.isLoading ? (
      <Spinner />
    ) : (
      <Switch>
        <Route path="/private/:room" component={Chat} />
        <Route path="/:room" component={Chat} />
        <Route exact path="/" component={Home} />
      </Switch>
    );
  }
}

const mapStateFromProps = (state) => ({
  isLoading: state.user.isLoading,
});

const RootWithAuth = withRouter(
  connect(mapStateFromProps, { setUser, clearUser })(Root)
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
