import React from "react";
import { Header, Segment, Input, Icon } from "semantic-ui-react";
import "./MessageHeader.css";

class MessagesHeader extends React.Component {
  isAdmin(user) {
    if (user) {
      return user.uid == "AoL6caFbeJh8Wm59uK3qcK8pqth2";
    }
  }

  render() {
    const {
      user,
      channel,
      channelName,
      numUniqueUsers,
      handleSearchChange,
      searchLoading,
      isPrivateChannel,
      handleStar,
      isChannelStarred,
      handleSidePanel,
      handleMetaPanel,
      metaPanelToggle,
    } = this.props;

    // console.log('messagesheaderch', channel);
    return (
      <div className="messageHeaderContainer">
        {/* Channel Title */}
        <div className="leftHeader">
          <div onClick={handleSidePanel} className="roomIcon">
            {/* {channelName.toString().charAt(1)} */}
            <Icon className="functionIcon" name="bars" size="small" />
          </div>
          <Header as="h2" style={{ marginBottom: 0, marginTop: 0 }}>
            <span>
              {channelName}
              {this.isAdmin(user) && !isPrivateChannel && (
                <Icon
                  onClick={handleStar}
                  name={isChannelStarred ? "star" : "star outline"}
                  color={isChannelStarred ? "yellow" : "black"}
                />
              )}
            </span>
            {channel && channel.topic && (
              <Header.Subheader>{channel.topic}</Header.Subheader>
            )}
          </Header>
        </div>

        {/* Channel Search Input */}
        <Header floated="right">
          {/* <Input
            loading={searchLoading}
            onChange={handleSearchChange}
            size="mini"
            icon="search"
            name="searchTerm"
            placeholder="Search Messages"
          /> */}
          {/* <div onClick={handleMetaPanel} className="actionIcon">
            <Icon
              style={metaPanelToggle ? {margin: 0} : {marginLeft: 0.25}}
              name="ellipsis horizontal"
              color="black"
            />
            {!metaPanelToggle
              ? <p></p>
              : <span></span>
            }

          </div> */}
        </Header>
      </div>
    );
  }
}

export default MessagesHeader;
