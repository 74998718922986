import React from "react";
import { Segment, Comment, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { setUserPosts } from "../../actions";
import firebase from "../../firebase";
import "./Messages.css";
import MessagesHeader from "./MessagesHeader";
import MessageForm from "./MessageForm";
import Message from "./Message";
import Typing from "./Typing";
import Skeleton from "./Skeleton";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: window.location.pathname,
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};

class Messages extends React.Component {
  state = {
    privateChannel: this.props.isPrivateChannel,
    privateMessagesRef: firebase.database().ref("privateMessages"),
    messagesRef: firebase.database().ref("messages"),
    pinnedChannelsRef: firebase.database().ref("pinnedChannels"),
    userFlagsRef: firebase.firestore().collection("user_flags"),
    messages: [],
    messagesLoading: true,
    channel: this.props.currentChannel,
    isChannelStarred: false,
    user: this.props.currentUser,
    usersRef: firebase.database().ref("users"),
    numUniqueUsers: "",
    searchTerm: "",
    searchLoading: false,
    searchResults: [],
    typingRef: firebase.database().ref("typing"),
    typingUsers: [],
    connectedRef: firebase.database().ref(".info/connected"),
    listeners: [],
    sidePanelToggle: this.props.sidePanelToggle,
    metaPanelToggle: this.props.metaPanelToggle
  };

  componentDidMount() {
    const { channel, user, listeners } = this.state;
    this.setState({
      messagesLoading: true
    });
  }

  componentWillUnmount() {
    this.removeListeners(this.state.listeners);
    this.state.connectedRef.off();
  }

  removeListeners = listeners => {
    listeners.forEach(listener => {
      listener.ref.child(listener.id).off(listener.event);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.messagesEnd) {
      this.scrollToBottom();
    }
  }

  addToListeners = (id, ref, event) => {
    const index = this.state.listeners.findIndex(listener => {
      return (
        listener.id === id && listener.ref === ref && listener.event === event
      );
    });

    if (index === -1) {
      const newListener = { id, ref, event };
      this.setState({ listeners: this.state.listeners.concat(newListener) });
    }
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  addListeners = channelName => {
    this.addMessageListener(channelName);
    this.addTypingListeners(channelName);
  };

  addTypingListeners = channelName => {
    if (!channelName) return false;
    let typingUsers = [];
    this.state.typingRef.child(channelName).on("child_added", snap => {
      if (snap.key !== this.state.user.uid) {
        typingUsers = typingUsers.concat({
          id: snap.key,
          name: snap.val()
        });
        this.setState({ typingUsers });
      }
    });
    this.addToListeners(channelName, this.state.typingRef, "child_added");

    this.state.typingRef.child(channelName).on("child_removed", snap => {
      const index = typingUsers.findIndex(user => user.id === snap.key);
      if (index !== -1) {
        typingUsers = typingUsers.filter(user => user.id !== snap.key);
        this.setState({ typingUsers });
      }
    });
    this.addToListeners(channelName, this.state.typingRef, "child_removed");

    this.state.connectedRef.on("value", snap => {
      if (snap.val() === true) {
        this.state.typingRef
          .child(channelName)
          .child(this.state.user.uid)
          .onDisconnect()
          .remove(err => {
            if (err !== null) {
              console.error(err);
            }
          });
      }
    });
  };

  addMessageListener = channelName => {
    let loadedMessages = [];
    const ref = this.getMessagesRef();

    if (channelName) {
      ref
        .child(channelName)
        .limitToLast(30)
        .on("child_added", snap => {
          const message = {
            ...snap.val(),
            key: snap.key
          };
          loadedMessages.push(message);

          this.setState({
            messages: loadedMessages,
            messagesLoading: false
          });
          this.countUniqueUsers(loadedMessages);
          this.countUserPosts(loadedMessages);
        });

      var _this = this;
      ref.child("messages").on("value", function(snapshot) {
        if (snapshot.numChildren() == 0) {
          _this.setState({
            messagesLoading: false
          });
        }
      });

      this.addToListeners(channelName, ref, "child_added");
    }
  };

  addUserStarsListener = channelName => {
    if (!channelName) return false;
    this.state.pinnedChannelsRef
      .child(channelName)
      .once("value")
      .then(data => {
        let prevStarred = false;
        if (data.val() !== null) {
          prevStarred = true;
        }
        this.setState({ isChannelStarred: prevStarred });
      });
  };

  getMessagesRef = () => {
    const { messagesRef, privateMessagesRef, privateChannel } = this.state;
    return privateChannel ? privateMessagesRef : messagesRef;
  };

  handleStar = () => {
    this.setState(
      prevState => ({
        isChannelStarred: !prevState.isChannelStarred
      }),
      () => this.starChannel()
    );
  };

  handleSidePanel = () => {
    this.props.toggleSidePanel(!this.props.sidePanelToggle);
  };

  handleMetaPanel = () => {
    this.props.toggleMetaPanel(!this.props.metaPanelToggle);
  };

  starChannel = () => {
    if (this.state.isChannelStarred) {
      this.state.pinnedChannelsRef
        .child(this.state.channel.name)
        .set(this.state.channel);
    } else {
      this.state.pinnedChannelsRef
        .child(this.state.channel.name)
        .remove(err => {
          if (err !== null) {
            console.error(err);
          }
        });
    }
  };

  handleSearchChange = event => {
    this.setState(
      {
        searchTerm: event.target.value,
        searchLoading: true
      },
      () => this.handleSearchMessages()
    );
  };

  handleSearchMessages = () => {
    const channelMessages = [...this.state.messages];
    const regex = new RegExp(this.state.searchTerm, "gi");
    const searchResults = channelMessages.reduce((acc, message) => {
      if (
        (message.content && message.content.match(regex)) ||
        message.user.name.match(regex)
      ) {
        acc.push(message);
      }
      return acc;
    }, []);
    this.setState({ searchResults });
    setTimeout(() => this.setState({ searchLoading: false }), 1000);
  };

  handleFlagUser = (user_id, message_id) => {
    const currentUserId = this.state.user.uid;
    const id = `${user_id}|${message_id}|${currentUserId}`;

    this.state.userFlagsRef
      .doc(id)
      .set({
        message_id,
        flagged_user_id: user_id,
        flagged_by_user_id: this.state.user.uid,
        created: new Date()
      })
      .then(res => {
        console.log("res", res);
      })
      .catch(err => console.log(err));
  };

  countUniqueUsers = messages => {
    const uniqueUsers = messages.reduce((acc, message) => {
      if (!acc.includes(message.user.name)) {
        acc.push(message.user.name);
      }
      return acc;
    }, []);
    const plural = uniqueUsers.length > 1 || uniqueUsers.length === 0;
    const numUniqueUsers = `${uniqueUsers.length} user${plural ? "s" : ""}`;
    this.setState({ numUniqueUsers });
  };

  countUserPosts = messages => {
    let userPosts = messages.reduce((acc, message) => {
      if (message.user.name in acc) {
        acc[message.user.name].count += 1;
      } else {
        acc[message.user.name] = {
          avatar: message.user.avatar,
          count: 1
        };
      }
      return acc;
    }, {});
    this.props.setUserPosts(userPosts);
  };

  displayMessages = messages => {
    if (messages.length > 0) {
      return messages.map(message => (
        <Message
          key={message.timestamp}
          message={message}
          user={this.state.user}
          flagUser={this.handleFlagUser}
        />
      ));
    } else {
      return (
        <div>
          <h5>You are the first on the chat!</h5>
        </div>
      );
    }
  };

  displayChannelName = channel => {
    return channel
      ? `${this.state.privateChannel ? "@" : "#"}${channel.name}`
      : "";
  };

  displayTypingUsers = users =>
    users.length > 0 &&
    users.map(user => (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "0.2em" }}
        key={user.id}
      >
        <span className="user__typing">{user.name} is typing</span> <Typing />
      </div>
    ));

  displayMessageSkeleton = loading =>
    loading ? (
      <React.Fragment>
        {[...Array(10)].map((_, i) => (
          <Skeleton key={i} />
        ))}
      </React.Fragment>
    ) : null;

  render() {
    // prettier-ignore
    const { messagesRef, messages, channel, user, numUniqueUsers, searchTerm, searchResults, searchLoading, privateChannel, isChannelStarred, typingUsers, messagesLoading } = this.state;

    return (
      <React.Fragment>
        <div className="messagesContainer">
          <div className="messagesHeader">
            <MessagesHeader
              user={user}
              channelName={this.displayChannelName(channel)}
              numUniqueUsers={numUniqueUsers}
              handleSearchChange={this.handleSearchChange}
              searchLoading={searchLoading}
              isPrivateChannel={privateChannel}
              handleStar={this.handleStar}
              isChannelStarred={isChannelStarred}
              metaPanelToggle={this.props.metaPanelToggle}
              handleSidePanel={this.handleSidePanel}
              handleMetaPanel={this.handleMetaPanel}
            />
            <div
              style={{
                clear: "both",
                paddingTop: 10,
                borderBottom: "1px solid #ccc"
              }}
            />
          </div>
          <div className="messagesBody">
            <div className="messagesHolderForScroll">
              <Comment.Group size="large" className="messages">
                {this.displayMessageSkeleton(messagesLoading)}

                <div ref={node => (this.messagesEnd = node)} />
              </Comment.Group>
            </div>
          </div>

          <MessageForm
            className="messageForm"
            messagesRef={messagesRef}
            currentChannel={channel}
            currentUser={user}
            isPrivateChannel={privateChannel}
            getMessagesRef={this.getMessagesRef}
          />

          <Modal
            size="tiny"
            dimmer="blurring"
            open={true}
            // onClose={.closeModal}
          >
            <Modal.Header>You need to sign in first</Modal.Header>
            <Modal.Content>

              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </Modal.Content>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { setUserPosts })(Messages);
