import React from "react";
import firebase from "../../firebase";
import { connect } from "react-redux";
import { setCurrentChannel, setPrivateChannel } from "../../actions";
import { Menu, Icon } from "semantic-ui-react";

class PinnedChannels extends React.Component {
  state = {
    user: this.props.currentUser,
    usersRef: firebase.database().ref("users"),
    pinnedChannelsRef: firebase.database().ref("pinnedChannels"),
    activeChannel: "",
    pinnedChannels: []
  };

  componentDidMount() {
    if (this.state.user) {
      this.addListeners(this.state.user.uid);
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  removeListener = () => {
    this.state.usersRef.child(`${this.state.user.uid}/pinned`).off();
  };

  addListeners = () => {
    this.state.pinnedChannelsRef.on("child_added", snap => {
      const pinnedChannel = snap.val();
      console.log("pinnedChannel", pinnedChannel);
      this.setState({
        pinnedChannels: [...this.state.pinnedChannels, pinnedChannel]
      });
    });

    this.state.pinnedChannelsRef.on("child_removed", snap => {
      console.log('sna', snap.val());
        const channelToRemove = { ...snap.val() };
        const filteredChannels = this.state.pinnedChannels.filter(channel => {
          return channel.name !== channelToRemove.name;
        });
        this.setState({ pinnedChannels: filteredChannels });
      });
  };

  setActiveChannel = channel => {
    this.setState({ activeChannel: channel.name });
  };

  changeChannel = channel => {
    this.joinChannel(channel);
    // this.setActiveChannel(channel);
    // this.props.setCurrentChannel(channel);
    // this.props.setPrivateChannel(false);
  };

  joinChannel = channel => {
    this.state.usersRef
      .child(this.state.user.uid)
      .child('channels')
      .child(channel.name)
      .update(channel)
  }

  displayChannels = pinnedChannels => {
    console.log('display channels', pinnedChannels);
    return pinnedChannels.length > 0 &&
      pinnedChannels.map(channel => (
        <Menu.Item
          key={channel.name}
          onClick={() => this.changeChannel(channel)}
          name={channel.name}
          style={{ opacity: 0.7 }}
          active={channel.name === this.state.activeChannel}
        >
          # {channel.name}
        </Menu.Item>
      ));
  };

  render() {
    const { pinnedChannels } = this.state;
    return (
      <Menu.Menu className="menu">
        <Menu.Item>
          <span>
            <Icon name="star" /> POPULAR ROOMS
          </span>
        </Menu.Item>
        {this.displayChannels(pinnedChannels)}
      </Menu.Menu>
    );
  }
}

export default connect(null, { setCurrentChannel, setPrivateChannel })(
  PinnedChannels
);
