import React from "react";
import {
  Segment,
  Accordion,
  Header,
  Icon,
  Image,
  List,
} from "semantic-ui-react";
import "./MetaPanel.css";

class MetaPanel extends React.Component {
  state = {
    channel: this.props.currentChannel,
    privateChannel: this.props.isPrivateChannel,
    activeIndex: 0,
  };

  setActiveIndex = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  formatCount = (num) =>
    num > 1 || num === 0 ? `${num} posts` : `${num} post`;

  displayTopPosters = (posts) =>
    Object.entries(posts)
      .sort((a, b) => b[1] - a[1])
      .map(([key, val], i) => (
        <List.Item key={i}>
          <Image avatar src={val.avatar} />
          <List.Content>
            <List.Header as="a">{key}</List.Header>
            <List.Description>{this.formatCount(val.count)}</List.Description>
          </List.Content>
        </List.Item>
      ))
      .slice(0, 2);

  render() {
    const { activeIndex, privateChannel, channel } = this.state;
    const { userPosts, handleMetaPanel } = this.props;

    if (privateChannel) return null;

    return (
      <div className="metaPanelContainer" loading={!channel ? "false" : "true"}>
        <div className="metaPanelHeader">
          <Header as="h3" style={{ margin: 0 }}>
            About #{channel && channel.name}
          </Header>
          <div className="metaPanelHeaderAction">
            <div
              onClick={() => handleMetaPanel(false)}
              className="closeMetaPanelIcon"
            >
              x
            </div>
          </div>
        </div>

        <div className="metaPanelAccordion">
          <Accordion style={{ borderRadius: 0 }} styled attached="true">
            {/* <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.setActiveIndex}
            >
              <Icon name="dropdown" />
              <Icon name="info" />
              Topic of the Day
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              {channel && channel.details ? channel.details : 'We are looking for moderators!'}
            </Accordion.Content> */}

            {/* <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.setActiveIndex}
            >
              <Icon name="dropdown" />
              <Icon name="user circle" />
              Top Posters
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <List>{userPosts && this.displayTopPosters(userPosts)}</List>
            </Accordion.Content> */}

            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={this.setActiveIndex}
            >
              <Icon name="dropdown" />
              <Icon name="pencil alternate" />
              Users Online
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <List selection verticalAlign="middle">
                <List.Item>
                  <Image avatar src="/images/avatar/small/helen.jpg" />
                  <List.Content>
                    <List.Header>Helen</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Image avatar src="/images/avatar/small/christian.jpg" />
                  <List.Content>
                    <List.Header>Christian</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Image avatar src="/images/avatar/small/daniel.jpg" />
                  <List.Content>
                    <List.Header>Daniel</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Accordion.Content>

            {/* <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.setActiveIndex}
            >
              <Icon name="dropdown" />
              <Icon name="user circle" />
              Sponsors
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>By becoming a sponsor, you will be able to promote a banner of your choice on this channel.</p>
              <p>Cost: $10/month</p>
              <p><a href="https://typeform.com" target="_blank">Become a sponsor</a></p>
            </Accordion.Content> */}
          </Accordion>
        </div>

        {/* <div className="metaImages">
          <img src="https://ocfair.com/wp-content/uploads/2019/06/Sponsor-Logos-Tiering-2019.jpg"></img>
        </div> */}
      </div>
    );
  }
}

export default MetaPanel;
