import React from "react";
import { withRouter } from "react-router-dom";
import firebase from "../../firebase";
import { connect } from "react-redux";
import {
  setCurrentChannel,
  setPrivateChannel,
  joinChannel,
  joinPrivateChannel,
  joinExistingPrivateChannel,
  leaveChannel,
  leavePrivateChannel,
  getChannels,
  getNotifCount,
} from "../../actions";
// prettier-ignore
import { Menu, Icon, Modal, Table, Header, Form, Input, Button, Label } from "semantic-ui-react";
import "./Channels.css";
import ListChannelsCard from "../ListChannelsCard";

class Channels extends React.Component {
  state = {
    user: this.props.currentUser,
    channel: null,
    channels: [],
    allChannels: [],
    channelDetails: "",
    channelsRef: firebase.database().ref("channels"),
    messagesRef: firebase.database().ref("messages"),
    typingRef: firebase.database().ref("typing"),
    usersRef: firebase.database().ref("users"),
    pinnedChannelsRef: firebase.database().ref("pinnedChannels"),
    pinnedChannels: [],
    privateChannelsRef: firebase.database().ref("privateChannels"),
    privateChannels: [],
    notifications: {},
    modal: false,
    firstLoad: true,
    roomMenu: {
      toggle: false,
      itemNum: null,
    },
  };

  componentDidMount() {
    console.log("initial load", this.props.match);
    const channelName = this.props.match.params.room;
    if (
      this.props.history.location.pathname.replace(/^\/([^\/]*).*$/, "$1") ===
      "private"
    ) {
      this.joinExistingPrivateChannel(channelName);
    } else {
      this.joinChannel(channelName);
    }
    this.addListeners(this.state.user);
    this.getChannels();
    this.getPrivateChannels();
    this.getAllChannels();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  getAllChannels() {
    this.state.channelsRef
      .orderByChild("membersCount")
      .limitToLast(30)
      .on("value", (snapshot) => {
        const channels = snapshot.val();
        let sortedChannels = {};
        Object.keys(channels)
          .sort(function (a, b) {
            return channels[b].membersCount - channels[a].membersCount;
          })
          .forEach(function (key) {
            sortedChannels[key] = channels[key];
          });
        this.setState({ allChannels: sortedChannels });
      });
  }

  nextChannel() {
    const { channels } = this.state;
    const { currentChannel } = this.props;
    const currentChannelIndex = channels.findIndex(
      (c) => c.name === currentChannel.name
    );

    const nextChannel =
      channels[currentChannelIndex + 1] || channels[currentChannelIndex - 1];

    this.props.history.push(`/${nextChannel.name}`);
    this.props.joinChannel(nextChannel.name);
  }

  updateNumUsersInChannel(channel, isPrivate) {
    console.log("updaing nm users", channel);

    const channelsRef = isPrivate
      ? this.state.privateChannelsRef
      : this.state.channelsRef;
    channelsRef
      .child(`${channel.name}/members/${this.state.user.uid}`)
      .set(true);
    channelsRef
      .child(`${channel.name}/members/${this.state.user.uid}`)
      .onDisconnect()
      .remove();
  }

  getChannels = () => {
    this.state.usersRef
      .child(this.state.user.uid)
      .child("channels")
      .once("value", (snap) => {
        console.log("getChannellsss", snap.val());
        const objects = snap.val();
        let channels = [];
        Object.keys(objects).map((k) => {
          const ch = this.state.channelsRef.child(k).on("value", (snap) => {
            console.log("my user rooms", snap.val());
            const channel = snap.val();
            this.addNotifCount(channel);
            const c = channels.findIndex((c) => c.id === channel.id);
            if (c < 0) {
              channels.push(snap.val());
            } else {
              if (channel.members && channel.members[this.state.user.uid]) {
                let newChannels = [...this.state.channels];
                newChannels[c] = { ...newChannels[c], ...channel };
                newChannels[c] = { ...newChannels[c], ...channel };
                channels = newChannels;
                this.setState({ channels });
              } else {
                channels = channels.splice(c, 1);
              }
            }
          });
        });
        this.setState({ channels });
      });
  };

  getPrivateChannels = () => {
    this.state.usersRef
      .child(this.state.user.uid)
      .child("privateChannels")
      .once("value", (snap) => {
        const objects = snap.val() || {};
        let privateChannels = [];
        Object.keys(objects).map((k) => {
          const ch = this.state.privateChannelsRef
            .child(k)
            .on("value", (snap) => {
              const channel = snap.val();
              this.addNotifCount(channel, true);
              const c = privateChannels.findIndex((c) => c.id === channel.id);
              if (c < 0) {
                privateChannels.push(snap.val());
              } else {
                if (channel.members && channel.members[this.state.user.uid]) {
                  let newChannels = [...this.state.privateChannels];
                  newChannels[c] = { ...newChannels[c], ...channel };
                  newChannels[c] = { ...newChannels[c], ...channel };
                  privateChannels = newChannels;
                  this.setState({ privateChannels });
                } else {
                  privateChannels = privateChannels.splice(c, 1);
                }
              }
            });
        });
        this.setState({ privateChannels });
      });
  };

  addListeners = (user) => {
    this.state.usersRef
      .child(user.uid)
      .child("channels")
      .on("child_added", (snap) => {
        const _this = this;
        setTimeout(function () {
          _this.getChannels();
        }, 200);
        const ch = snap.val();
        // this.state.channelsRef.child(ch.name).on("value", (chanSnap) => {
        //   const chan = chanSnap.val();
        //   const index = this.state.channels.findIndex((c) => c.id === chan.id);
        //   const stateChannels = this.state.channels;
        //   if (index === -1) {
        //     stateChannels.push(chan);
        //   } else {
        //     stateChannels[index] = chan;
        //   }
        //   this.setState({ channels: stateChannels });
        // });
        if ("created" in ch) {
          this.updateNumUsersInChannel(ch);
        }
      });

    // // when child removed, update user's rooms
    this.state.usersRef
      .child(user.uid)
      .child("channels")
      .on("child_removed", (snap) => {
        const _this = this;
        setTimeout(function () {
          _this.getChannels();
        }, 200);

        if (snap.val().id === this.props.currentChannel.id) {
          this.nextChannel();
        }
        const myChannel = { id: snap.key, ...snap.val() };
        const removedChannelList = this.state.channels.filter(
          (c) => c.id != myChannel.id
        );
        console.log("myChannel remove", myChannel);
        console.log("newChannelList", removedChannelList);
      });

    this.state.usersRef
      .child(user.uid)
      .child("privateChannels")
      .on("child_added", (snap) => {
        this.getPrivateChannels();
        const ch = snap.val();
        this.updateNumUsersInChannel(ch, "isPrivate");
      });

    this.state.usersRef
      .child(user.uid)
      .child("privateChannels")
      .on("child_removed", (snap) => {
        this.getPrivateChannels();

        if (snap.val().id === this.props.currentChannel.id) {
          this.props.history.push(`/lobby`);
          this.props.joinChannel("lobby");
        }
      });

    this.state.pinnedChannelsRef.on("child_added", (snap) => {
      const pinnedChannel = snap.val();
      this.setState({
        pinnedChannels: [...this.state.pinnedChannels, pinnedChannel],
      });
    });

    this.state.pinnedChannelsRef.on("child_removed", (snap) => {
      const channelToRemove = { ...snap.val() };
      const filteredChannels = this.state.pinnedChannels.filter((channel) => {
        return channel.name !== channelToRemove.name;
      });
      this.setState({ pinnedChannels: filteredChannels });
    });
  };

  // addNotificationListener = (channel) => {
  //   this.state.messagesRef.child(channel.name).on("value", (snap) => {
  //     if (this.state.channel) {
  //       this.handleNotifications(
  //         channel.id,
  //         this.state.channel.id,
  //         this.state.notifications,
  //         snap
  //       );
  //     }
  //   });
  // };

  // handleNotifications = (channelId, currentChannelId, notifications, snap) => {
  //   let lastTotal = 0;
  //   let index = notifications.findIndex(
  //     (notification) => notification.id === channelId
  //   );

  //   if (index !== -1) {
  //     if (channelId !== currentChannelId) {
  //       lastTotal = notifications[index].total;

  //       if (snap.numChildren() - lastTotal > 0) {
  //         notifications[index].count = snap.numChildren() - lastTotal;
  //       }
  //     }
  //     notifications[index].lastKnownTotal = snap.numChildren();
  //   } else {
  //     notifications.push({
  //       id: channelId,
  //       total: snap.numChildren(),
  //       lastKnownTotal: snap.numChildren(),
  //       count: 0,
  //     });
  //   }

  //   this.setState({ notifications });
  // };

  removeListeners = () => {
    this.state.channelsRef.off();
    this.state.channels.forEach((channel) => {
      this.state.messagesRef.child(channel.name).off();
    });
  };

  joinChannel = (rawChannelName) => {
    console.log("joining", rawChannelName);
    this.closeModal();

    // sanitize channel name to have no spaces
    const channelName = rawChannelName
      .trim()
      .split(" ")
      .join("-")
      .split(".")
      .join("_")
      .toLowerCase();

    if (
      this.props.currentChannel &&
      channelName !== this.props.currentChannel.name
    ) {
      this.props.setPanelLoading(true);
    }

    this.props.joinChannel(channelName).then((res) => {
      if (res) {
        this.addNotifCount(res.payload.currentChannel);
      }

      if (this.props.widthSize < 769) {
        this.props.handleSidePanel();
      }
    });
    // window.location.href = `/${channelName}`;
    this.props.history.push(`/${channelName}`);
  };

  joinExistingPrivateChannel = (rawChannelName) => {
    const channelName = rawChannelName;
    this.props.joinExistingPrivateChannel(channelName).then((res) => {
      if (this.props.widthSize < 769) {
        this.props.handleSidePanel();
      }
    });
  };

  joinPrivateChannel = (channel) => {
    this.props.setPanelLoading(true);
    let chatee = null;
    if (this.state.user.uid === channel.chatter.id) {
      chatee = channel.chatee;
    }
    if (this.state.user.uid === channel.chatee.id) {
      chatee = channel.chatter;
    }

    this.props.joinPrivateChannel(chatee).then((res) => {
      this.addNotifCount(res.payload.currentChannel, true);
      if (this.props.widthSize < 769) {
        this.props.handleSidePanel();
      }
    });

    this.props.history.push(`/private/${channel.name}`);
  };

  leaveChannel = (channel) => {
    if (channel.name == "lobby") {
      alert("You can't remove this channel");
      this.closeRoomMenu();
      return false;
    }

    // this.state.channelsRef.child(`${channel.name}/members/${this.state.user.uid}`).remove();

    this.props.setPanelLoading(true);
    this.props
      .leaveChannel(this.state.user.uid, channel.name, this.state.channels)
      .then((channel) => {
        this.props.setPanelLoading(false);
        //this.props.history.push(`/${channel.name}`)
      });
  };

  leavePrivateChannel = (channel) => {
    this.props
      .leavePrivateChannel(this.state.user.uid, channel.name)
      .then(() => {});
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.joinChannel(this.state.channelName);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeChannel = (channel) => {
    this.props.history.push(`/${channel.name}`);
    if (this.props.widthSize < 769) {
      this.props.handleSidePanel();
    }
    this.joinChannel(channel.name);
  };

  // clearNotifications = () => {
  //   let index = this.state.notifications.findIndex(
  //     (notification) => notification.id === this.state.channel.id
  //   );

  //   if (index !== -1) {
  //     let updatedNotifications = [...this.state.notifications];
  //     updatedNotifications[index].total = this.state.notifications[
  //       index
  //     ].lastKnownTotal;
  //     updatedNotifications[index].count = 0;
  //     this.setState({ notifications: updatedNotifications });
  //   }
  // };

  addNotifCount = (channel, isPrivate) => {
    this.props.getNotifCount(channel.name, isPrivate).then((count) => {
      this.setState({
        notifications: { ...this.state.notifications, [channel.id]: count },
      });
    });
  };

  joinPinnedChannel = (channel) => {
    this.state.usersRef
      .child(this.state.user.uid)
      .child("channels")
      .child(channel.name)
      .update(channel);

    this.joinChannel(channel.name);
  };

  displayPinnedChannels = (pinnedChannels) => {
    return (
      pinnedChannels.length > 0 &&
      pinnedChannels.map((channel) => (
        <Menu.Item
          key={channel.name}
          onClick={() => this.joinPinnedChannel(channel)}
          name={channel.name}
          style={{ opacity: 0.7 }}
        >
          # {channel.name}
        </Menu.Item>
      ))
    );
  };

  displayChannelName = (channel) => {
    let channelName = null;
    if (this.state.user.uid === channel.chatter.id) {
      channelName = channel.chatee.name;
    }
    if (this.state.user.uid === channel.chatee.id) {
      channelName = channel.chatter.name;
    }
    return channelName;
  };

  displayPrivateChannels = (privateChannels) => {
    return (
      privateChannels.length > 0 &&
      privateChannels.map((channel) => (
        <div key={channel.name} className="channelBox">
          <Menu.Item
            onClick={() => this.joinPrivateChannel(channel)}
            name={channel.name}
            style={{ opacity: 0.7 }}
            active={
              this.props && this.props.currentChannel
                ? channel.name === this.props.currentChannel.name
                : false
            }
          >
            {this.state.notifications[channel.id] ? (
              <Label color="red">{this.state.notifications[channel.id]}</Label>
            ) : null}
            @ {this.displayChannelName(channel)}{" "}
            {channel.membersCount > 1 ? (
              <Icon name="checkmark" style={{ float: "none" }} />
            ) : null}
          </Menu.Item>
          <div className="leaveChannelButtonBox">
            <span title="Leave Chat">
              <Icon
                onClick={() => this.leavePrivateChannel(channel)}
                name="close"
                style={{
                  fontWeight: "normal",
                  fontSize: "0.6rem",
                  color: "#9999",
                }}
              />
            </span>
          </div>
        </div>
      ))
    );
  };

  openRoomMenu(index) {
    this.setState({
      roomMenu: {
        toggle: true,
        itemNum: index,
      },
    });
  }
  closeRoomMenu() {
    this.setState({
      roomMenu: {
        toggle: false,
        itemNumber: null,
      },
    });
  }

  displayChannels = (channels) =>
    channels.length > 0 &&
    channels.map((channel, index) => {
      return (
        <div key={index} className="channelBox">
          <Menu.Item
            key={channel.name}
            onClick={() => this.joinChannel(channel.name)}
            name={channel.name}
            style={{ opacity: 0.7 }}
            active={
              this.props && this.props.currentChannel
                ? channel.name === this.props.currentChannel.name
                : false
            }
          >
            {this.state.notifications[channel.id] ? (
              <Label color="red">{this.state.notifications[channel.id]}</Label>
            ) : null}
            # {channel.name} ({channel.membersCount})
          </Menu.Item>
          <div className="leaveChannelButtonBox">
            <span title="Leave Room">
              <Icon
                onClick={() => this.leaveChannel(channel)}
                name="close"
                style={{
                  fontWeight: "normal",
                  fontSize: "0.6rem",
                  color: "#9999",
                }}
              />
            </span>
          </div>
          {/* {
          (this.state.roomMenu.toggle && this.state.roomMenu.itemNum == index) &&
          <div className="roomMenuContainer">
            <div onClick={() => this.closeRoomMenu()} className="roomMenuOverlay" />
            <div className="roomMenu">
              <div className="roomMenuItem">
                <p onClick={() => this.leaveChannel(channel)}>
                  Leave Room
                </p>
              </div>
            </div>
          </div>
        } */}
        </div>
      );
    });

  isFormValid = ({ channelName }) => channelName;

  openModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => this.setState({ modal: false });

  render() {
    const { channels, modal, pinnedChannels, privateChannels } = this.state;
    // console.log('channels', channels);
    return (
      <React.Fragment>
        <Menu.Menu className="menu">
          <Menu.Item>
            <span>
              <Icon name="exchange" /> My Rooms
            </span>{" "}
            ({channels.length}){" "}
            {/* <Icon
              name="add"
              style={{ cursor: 'pointer' }}
              onClick={this.openModal}
            /> */}
          </Menu.Item>
          {this.displayChannels(channels)}
          <Menu.Item
            key="addRoom"
            onClick={this.openModal}
            name="addRoom"
            style={{
              opacity: 0.7,
              cursor: "pointer",
              // fontWeight: 700,
              color: "white",
              fontSize: "0.6rem",
            }}
            active={false}
          >
            {/* {this.getNotificationCount(channel) && (
              <Label color="red">{this.getNotificationCount(channel)}</Label>
            )} */}
            + add a room
          </Menu.Item>
        </Menu.Menu>

        <Menu.Menu className="menu">
          <Menu.Item>
            <span>
              <Icon name="star" /> Popular Now
            </span>
          </Menu.Item>
          {this.displayPinnedChannels(pinnedChannels)}
        </Menu.Menu>

        <Menu.Menu className="menu">
          <Menu.Item>
            <span>
              <Icon name="user" /> Direct messages
            </span>
          </Menu.Item>
          {this.displayPrivateChannels(privateChannels)}
          {privateChannels.length === 0 && (
            <Menu.Item style={{ opacity: 0.7 }}>No messages yet</Menu.Item>
          )}
        </Menu.Menu>

        {/* Add Channel Modal */}
        <Modal
          size="mini"
          dimmer="blurring"
          open={modal}
          onClose={this.closeModal}
        >
          <Modal.Header>Create / Join a Room</Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <div className="inputButtonWrapper">
                  <Form.Field className="inputField">
                    <Input
                      fluid
                      autoFocus
                      placeholder="Room Name"
                      name="channelName"
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field className="inputButton">
                    <Button color="green" inverted onClick={this.handleSubmit}>
                      <Icon name="checkmark" /> Add
                    </Button>
                  </Form.Field>
                </div>
              </Form.Group>
            </Form>
          </Modal.Content>

          <Modal.Content className="modalFooter">
            <h3>Active Rooms</h3>
            <Table basic="very" style={{ width: "100%" }} celled collapsing>
              <Table.Body>
                {Object.keys(this.state.allChannels).map((k) => {
                  if (this.state.allChannels[k].membersCount > 0) {
                    return (
                      <Table.Row key={k}>
                        <Table.Cell
                          style={{
                            width: "80%",
                            overflow: "auto",
                            height: "50%",
                          }}
                        >
                          <Header as="h4">
                            <Header.Content>
                              <a
                                href="#"
                                onClick={() =>
                                  this.joinChannel(
                                    this.state.allChannels[k].name
                                  )
                                }
                              >
                                #{this.state.allChannels[k].name}
                              </a>
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.allChannels[k].membersCount} users
                        </Table.Cell>
                      </Table.Row>
                    );
                  }
                })}
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
});

export default withRouter(
  connect(mapStateToProps, {
    setCurrentChannel,
    setPrivateChannel,
    joinChannel,
    joinPrivateChannel,
    joinExistingPrivateChannel,
    leaveChannel,
    leavePrivateChannel,
    getChannels,
    getNotifCount,
  })(Channels)
);
