import React from "react";
import { Menu, Header, Icon, Loader } from "semantic-ui-react";
import "./SidePanel.css";

import UserPanel from "./UserPanel";
import Channels from "./Channels";
import DirectMessages from "./DirectMessages";
import Starred from "./Starred";
import PinnedChannels from "./PinnedChannels";

class SidePanel extends React.Component {
  handleSidePanel = (status) => {
    if (typeof status === Boolean && status === false) {
      this.props.toggleSidePanel(false);
    } else {
      this.props.toggleSidePanel(!this.props.sidePanelToggle);
    }
  };

  componentWillUpdate() {
    // this.SidePanelElement.addEventListener('contextmenu', (e) => {e.preventDefault(); return false})
  }

  render() {
    const { currentUser, primaryColor, sidePanelToggle } = this.props;

    return (
      <div
        className={
          this.props.sidePanelToggle ? "sidePanel is-active" : "sidePanel"
        }
        ref={(element) => (this.SidePanelElement = element)}
      >
        <div className="sidePanelContainer">
          <div className="logoContainer">
            <Header style={{ margin: 0 }} inverted floated="left" as="h2">
              <Icon name="slack hash" style={{ transform: "rotate(18deg)" }} />
              <Header.Content>ever.chat</Header.Content>
            </Header>

            {/* <div onClick={this.handleSidePanel} className="sidePanelAction is_for_mobile">
              <Icon inverted name="reply" />
            </div> */}
          </div>
          <Menu
            className="sidePanelMenu"
            size="large"
            inverted
            vertical
            style={{
              marginTop: 0,
              borderRadius: "0",
              scrollbarColor: "green",
              overflowY: "auto",
              maxHeight: "100vh",
              height: "100%",
              background: primaryColor,
              fontSize: "1.2rem",
            }}
          >
            <UserPanel primaryColor={primaryColor} currentUser={currentUser} />

            {/* <Starred currentUser={currentUser} /> */}
            <Channels
              handleSidePanel={this.handleSidePanel}
              currentUser={currentUser}
              widthSize={this.props.widthSize}
              setPanelLoading={this.props.setLoading}
            />
            {/* <DirectMessages currentUser={currentUser} /> */}
          </Menu>
        </div>
        <div
          onClick={() => this.handleSidePanel()}
          className="sidePanelOverlay"
        ></div>
        <div
          className={`sidePanelLoader ${this.props.loading ? "active" : null}`}
        >
          <Loader size="medium" active />
        </div>
      </div>
    );
  }
}

export default SidePanel;
