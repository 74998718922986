import React from "react";
import moment from "moment";
import { Comment, Image, Dropdown } from "semantic-ui-react";
import Linkify from "linkifyjs/react";

const isOwnMessage = (message, user) => {
  // return true;
  return message.user.id === user.uid ? "message__self" : "";
};

const isImage = (message) => {
  return message.hasOwnProperty("image") && !message.hasOwnProperty("content");
};

const timeFromNow = (timestamp) => moment(timestamp).fromNow();

const showActions = (e) => {
  console.log("show");
  e.target.style.display = "display";
};

const hideActions = (e) => {
  e.target.style.display = "none";
};

const Message = ({ message, user, privateChannel, flagUser }) => {
  const userContextOptions = [
    {
      key: "send-message",
      text: (
        <span onClick={() => privateChannel(message.user)}>Send a Message</span>
      ),
      disabled: false,
    },
    {
      key: "report-user",
      text: (
        <span onClick={() => flagUser(user.uid, message.key)}>Report User</span>
      ),
      disabled: false,
    },
  ];

  return (
    <Linkify>
      <Comment
        onMouseEnter={() => showActions}
        onMouseLeave={() => hideActions}
      >
        {/* <Comment.Avatar src={message.user.avatar} /> */}
        <Comment.Content
          style={{ marginLeft: 10 }}
          className={isOwnMessage(message, user)}
        >
          {!isOwnMessage(message, user) && (
            <Dropdown
              trigger={
                <Comment.Author as="a">{message.user.name}</Comment.Author>
              }
              options={isOwnMessage(message, user) ? [] : userContextOptions}
            />
          )}
          {isOwnMessage(message, user) && (
            <Comment.Author as="a">{message.user.name}</Comment.Author>
          )}
          <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>
          {isImage(message) ? (
            <Image src={message.image} className="message__image" />
          ) : (
            <Comment.Text>{message.content}</Comment.Text>
          )}
          <Comment.Actions style={{ display: "none" }}>
            <Comment.Action>like</Comment.Action>
            <Comment.Action>flag</Comment.Action>
          </Comment.Actions>
        </Comment.Content>
      </Comment>
    </Linkify>
  );
};

export default Message;
