import React from "react";
import firebase from "../firebase";
import {
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Message,
  Image,
  Icon,
  Text
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};

class Home extends React.Component {
  state = {
    email: "",
    password: "",
    errors: [],
    loading: false
  };

  displayErrors = errors =>
    errors.map((error, i) => <p key={i}>{error.message}</p>);

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.setState({ errors: [], loading: true });
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(signedInUser => {
          console.log(signedInUser);
        })
        .catch(err => {
          console.error(err);
          this.setState({
            errors: this.state.errors.concat(err),
            loading: false
          });
        });
    }
  };

  isFormValid = ({ email, password }) => email && password;

  handleInputError = (errors, inputName) => {
    return errors.some(error => error.message.toLowerCase().includes(inputName))
      ? "error"
      : "";
  };

  render() {
    const { email, password, errors, loading } = this.state;
    const trendingRooms = [
      "stocks",
      "javascript",
      "corona-virus",
      "vue",
      "gossip",
      "20s",
      "30s",
      "40s"
    ];
    return (
      <Grid textAlign="center" verticalAlign="middle" className="app" style={{height: '100%', margin: 0}}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src="/thanks.gif" size="large" />

          <div className="intro" style={{ marginTop: 20, marginBottom: 40 }}>
            Trending Rooms
            <br />
            {trendingRooms.map(room => (
              <Link style={{ fontSize: 16 }} to={`/${room}`}>
                #{room} {" "}
              </Link>
            ))}
          </div>

          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />

          {/* <Button
                animated="fade"
                style={{ marginTop: 0}}
                color="twitter"

                size="large"
                onClick={() => this.props.history.push("/login")}
              >
                <Button.Content visible>Start Chat</Button.Content>
                <Button.Content hidden>


                  Sign In <Icon name='arrow right' />
                </Button.Content>
              </Button> */}

          <div style={{ marginTop: 20, color: "green" }}>
            1,503 users ONLINE
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Home;
