import * as actionTypes from "./types";
import firebase from "../firebase";

const channelsRef = firebase.database().ref("channels");
const privateChannelsRef = firebase.database().ref("privateChannels");
const usersRef = firebase.database().ref("users");
const messagesRef = firebase.database().ref("messages");

/* User Actions */
export const setUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};

/* Channel Actions */
export const setCurrentChannel = (channel) => {
  return {
    type: actionTypes.SET_CURRENT_CHANNEL,
    payload: {
      currentChannel: channel,
    },
  };
};

export const setPrivateChannel = (channel) => {
  return {
    type: actionTypes.SET_PRIVATE_CHANNEL,
    payload: {
      currentChannel: channel,
    },
  };
};

export const setUserPosts = (userPosts) => {
  return {
    type: actionTypes.SET_USER_POSTS,
    payload: {
      userPosts,
    },
  };
};

/* Colors Actions */
export const setColors = (primaryColor, secondaryColor) => {
  return {
    type: actionTypes.SET_COLORS,
    payload: {
      primaryColor,
      secondaryColor,
    },
  };
};

export const joinChannel = (channelName) => {
  return async function (dispatch, getState) {
    const { user } = getState();
    const { currentUser } = user;

    const channel = await getChannel(channelName);

    if (channel) {
      userAddChannel(currentUser.uid, channelName, channel);
      return dispatch(setCurrentChannel(channel));
    }

    const newChannel = await createChannel(currentUser.uid, channelName);

    await userAddChannel(currentUser.uid, channelName, newChannel);

    return dispatch(setCurrentChannel(newChannel));
  };
};

const composeUserIds = (chatterId, chateeId) => {
  const userIds = [];
  userIds.push(chatterId);
  userIds.push(chateeId);
  return userIds.join("_");
};

export const joinPrivateChannel = (chatee) => {
  return async function (dispatch, getState) {
    const { user } = getState();
    const { currentUser } = user;
    const chatter = currentUser;

    const channelNameVariant1 = composeUserIds(chatter.uid, chatee.id);
    const channelNameVariant2 = composeUserIds(chatee.id, chatter.uid);

    const channelVariant1 = await getPrivateChannel(channelNameVariant1);
    const channelVariant2 = await getPrivateChannel(channelNameVariant2);

    let channel = null;
    let channelName = channelNameVariant1;
    if (channelVariant1) {
      channel = channelVariant1;
      channelName = channelNameVariant1;
    }
    if (channelVariant2) {
      channel = channelVariant2;
      channelName = channelNameVariant2;
    }

    if (channel) {
      await userAddPrivateChannel(chatter.uid, channelName, channel);
      //await userAddPrivateChannel(chatee.id, channelName, channel);
      return dispatch(setPrivateChannel(channel));
    }

    const newChannel = await createPrivateChannel(chatter, chatee);

    const chatterRef = usersRef.child(
      `${chatter.uid}/privateChannels/${newChannel.name}/readMessages`
    );
    const chateeRef = usersRef.child(
      `${chatee.uid}/privateChannels/${newChannel.name}/readMessages`
    );
    chatterRef.update({
      count: 0,
      lastId: null,
    });
    chateeRef.update({
      count: 0,
      lastId: null,
    });

    await userAddPrivateChannel(chatter.uid, channelName, newChannel);
    await userAddPrivateChannel(chatee.id, channelName, newChannel);

    return dispatch(setPrivateChannel(newChannel));
  };
};

export const addToPrivateChannel = (user, type, channelName) => {
  return async function (dispatch, getState) {
    const channel = await getPrivateChannel(channelName);
    console.log(user, channel);
    if (type === "chatter") {
      if (user.id !== channel.chatter.id) {
        return null;
      }
    }

    if (type === "chatee") {
      if (user.id !== channel.chatee.id) {
        return null;
      }
    }

    await userAddPrivateChannel(user.id, channelName, channel);
  };
};

export const joinExistingPrivateChannel = (channelName) => {
  return async function (dispatch, getState) {
    const { user } = getState();
    const { currentUser } = user;

    const channel = await getPrivateChannel(channelName);
    if (
      channel &&
      (currentUser.uid === channel.chatter.id ||
        currentUser.uid === channel.chatee.id)
    ) {
      if (currentUser.uid === channel.chatter.id) {
        await addToPrivateChannel(channel.chatter, "chatter", channel.name);
      } else if (currentUser.uid === channel.chatee.id) {
        addToPrivateChannel(channel.chatee, "chatee", channel.name);
      }

      return dispatch(setPrivateChannel(channel));
    }

    const lobbyChannel = await getChannel("lobby");

    return dispatch(setCurrentChannel(lobbyChannel));
  };
};

export const leaveChannel = (userId, channelName, currentChannels) => {
  return async function (dispatch) {
    userLeaveChannel(userId, channelName);
  };
};

export const leavePrivateChannel = (userId, channelName) => {
  return async function (dispatch) {
    await userLeavePrivateChannel(userId, channelName);
  };
};

function userLeaveChannel(userId, channelName) {
  return new Promise((resolve) => {
    channelsRef
      .child(`${channelName}/members/${userId}`)
      .remove()
      .then(resolve)
      .catch((err) => {
        console.error(err);
      });

    usersRef
      .child(`${userId}/channels/${channelName}`)
      .remove()
      .then(resolve)
      .catch((err) => {
        console.error(err);
      });
  });
}

function userLeavePrivateChannel(userId, channelName) {
  return new Promise((resolve) => {
    privateChannelsRef
      .child(`${channelName}/members/${userId}`)
      .remove()
      .then(resolve)
      .catch((err) => {
        console.error(err);
      });

    usersRef
      .child(`${userId}/privateChannels/${channelName}`)
      .remove()
      .then(resolve)
      .catch((err) => {
        console.error(err);
      });
  });
}

function getChannel(channelName) {
  return new Promise((resolve) => {
    channelsRef.child(channelName).once("value", (snapshot) => {
      resolve(snapshot.val());
    });
  });
}

function getPrivateChannel(channelName) {
  return new Promise((resolve) => {
    privateChannelsRef.child(channelName).once("value", (snapshot) => {
      resolve(snapshot.val());
    });
  });
}

function userAddChannel(userId, channelName, newChannel) {
  console.log("i added", channelName);
  return usersRef
    .child(userId)
    .child("channels")
    .child(channelName)
    .update(newChannel);
}

function userAddPrivateChannel(userId, channelName, newChannel) {
  console.log("i added (private)", channelName);
  return usersRef
    .child(userId)
    .child("privateChannels")
    .child(channelName)
    .update(newChannel);
}

async function createChannel(userId, channelName) {
  const key = channelsRef.push().key;
  const newChannel = {
    userId: userId,
    id: key,
    name: channelName,
    messagesCount: 0,
    topic: "",
    created: firebase.database.ServerValue.TIMESTAMP,
  };

  await channelsRef.child(channelName).update(newChannel);

  return newChannel;
}

async function createPrivateChannel(chatter, chatee) {
  const channelName = composeUserIds(chatter.uid, chatee.id);
  const key = privateChannelsRef.push().key;
  const newChannel = {
    chatter: {
      id: chatter.uid,
      name: chatter.displayName,
      avatar: chatter.photoURL,
    },
    chatee: {
      id: chatee.id,
      name: chatee.name,
      avatar: chatee.avatar,
    },
    id: key,
    name: channelName,
    created: firebase.database.ServerValue.TIMESTAMP,
  };

  await privateChannelsRef.child(channelName).update(newChannel);

  return newChannel;
}

export const getChannels = () => {
  return async function (dispatch, getState) {
    const { user } = getState();
    const { currentUser } = user;
    channelsRef.once("value", (snapshot) => {
      return new Promise((resolve) => {
        resolve(snapshot.val());
      });
    });
  };
};

const getLastMessageId = (messages) => {
  if (messages.length) {
    return messages[messages.length - 1].key;
  }

  return null;
};

const checkLastMessage = (messages, id) => {
  const index = messages.findIndex((message) => message.key === id);
  return index;
};

export const setReadMessages = (messages, channelName, isPrivateChannel) => {
  return async function (dispatch, getState) {
    const { user } = getState();
    const { currentUser } = user;

    let channel = await getChannel(channelName);
    if (isPrivateChannel) {
      channel = await getPrivateChannel(channelName);
    }

    if (!channel) {
      return null;
    }

    let ref = usersRef.child(
      `${currentUser.uid}/channels/${channel.name}/readMessages`
    );

    if (isPrivateChannel) {
      ref = usersRef.child(
        `${currentUser.uid}/privateChannels/${channel.name}/readMessages`
      );
    }

    const readMessages = await getReadMessages(
      currentUser.uid,
      channel.name,
      isPrivateChannel
    );
    let messagesCount = channel.messagesCount;

    let newCount = 0;
    let newLastId = null;

    if (!messagesCount) {
      const snapshot = await messagesRef
        .child(`/${channel.name}`)
        .once("value");
      messagesCount = snapshot.numChildren();
    }

    if (!readMessages) {
      newCount = messagesCount;
    } else {
      if (readMessages.lastId) {
        const lastMessageIndex = checkLastMessage(
          messages,
          readMessages.lastId
        );

        if (lastMessageIndex > -1) {
          const increment = messages.length - lastMessageIndex - 1;
          newCount = (readMessages.count || 0) + increment;
        } else {
          newCount = messagesCount;
        }
      } else {
        newCount = messagesCount;
      }
    }
    newLastId = getLastMessageId(messages);

    let newReadMessages = {
      count: newCount,
      lastId: newLastId,
    };

    ref.update(newReadMessages);
  };
};

function getReadMessages(userId, channelName, isPrivateChannel) {
  return new Promise((resolve) => {
    let ref = usersRef.child(`${userId}/channels/${channelName}/readMessages`);

    if (isPrivateChannel) {
      ref = usersRef.child(
        `${userId}/privateChannels/${channelName}/readMessages`
      );
    }

    ref.once("value", (snapshot) => {
      resolve(snapshot.val());
    });
  });
}

export const getNotifCount = (channelName, isPrivateChannel) => {
  return async function (dispatch, getState) {
    const { user } = getState();
    const { currentUser } = user;

    let channel = await getChannel(channelName);
    if (isPrivateChannel) {
      channel = await getPrivateChannel(channelName);
    }

    if (!channel) {
      return null;
    }

    const readMessages = await getReadMessages(
      currentUser.uid,
      channelName,
      isPrivateChannel
    );
    let messagesCount = channel.messagesCount;
    let notifCount = 0;

    if (!readMessages) {
      return null;
    }

    if (!messagesCount) {
      const snapshot = await messagesRef
        .child(`/${channel.name}`)
        .once("value");
      messagesCount = snapshot.numChildren();
    }

    if (readMessages.count) {
      notifCount = messagesCount - readMessages.count;
    } else {
      notifCount = messagesCount;
    }

    if (notifCount > 0) {
      return notifCount;
    }

    return 0;
  };
};
