import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import "./Chat.css";
import { connect } from "react-redux";
import Swipe from "react-easy-swipe";
import firebase from "../firebase";
import ColorPanel from "./ColorPanel/ColorPanel";
import SidePanel from "./SidePanel/SidePanel";
import Messages from "./Messages/Messages";
import MetaPanel from "./MetaPanel/MetaPanel";
import MessagesLoggedout from "./Messages/MessagesLoggedout";

// prettier-ignore
class Chat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      sidePanelToggle: true,
      metaPanelToggle: true,
      startTouchPositionX: 0,
      touchMovementDistanceX: 0,
      panelLoading: false
    }

    this.toggleSidePanel = this.toggleSidePanel.bind(this)
    this.toggleMetaPanel = this.toggleMetaPanel.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.onSwipeStart = this.onSwipeStart.bind(this)
    this.onSwipeMove = this.onSwipeMove.bind(this)
    this.onSwipeEnd = this.onSwipeEnd.bind(this)
  }
  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    if (window.innerWidth < 1281) {
      this.setState({
        sidePanelToggle: false,
        metaPanelToggle: false
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
  }

  toggleSidePanel (value) {
    this.setState({
      sidePanelToggle: value
    })
  }

  toggleMetaPanel (value) {
    this.setState({
      metaPanelToggle: value
    })
  }

  setPanelLoading = (bool) => {
    this.setState({ panelLoading: bool });
  };

  /* Swipe Actions */
  onSwipeStart(event) {
    console.log('Start Tracking Touch Movement')
  }

  onSwipeMove(position, event) {
    // console.log(`Moved x: ${position.x} px, y: ${position.y} px`, event)
    if (!this.state.startTouchPositionX) {
      this.setState({
        startTouchPositionX: position.x
      })
    }
    const startXPos = this.state.startTouchPositionX
    const distance = (position.x - startXPos) / 250 * 100 * 1.15
    console.log('this is the distance: ', Math.abs(distance))
    if (Math.abs(distance) > 50) {
      let direction
      startXPos > position.x ? direction = 'left' : direction = 'right'
      if (direction == 'left' && this.state.sidePanelToggle) {
        this.toggleSidePanel(false)
      }
      if (direction == 'right' && !this.state.sidePanelToggle) {
        this.toggleSidePanel(true)
      }
    }
    // console.log("startTouchPositionX: ", this.state.startTouchPositionX, position.x, this.state.touchMovementDistanceX)
  }

  onSwipeEnd(event) {
    console.log('Stop Tracking Touch Movement', event)
    this.setState({
      startTouchPositionX: 0
    })
  }

  render() {
    const {currentUser, currentChannel, isPrivateChannel, userPosts, primaryColor, secondaryColor, match} = this.props

    return (

      <Swipe
        className="app"
        style={{ background: secondaryColor, height: this.state.windowHeight }}
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}
      >
        {/* <ColorPanel
          key={currentUser && currentUser.name}
          currentUser={currentUser}
        /> */}
        {/* <Grid.Column width={4}> */}

        <SidePanel
          key={currentUser && currentUser.uid}
          currentUser={currentUser}
          primaryColor={primaryColor}
          sidePanelToggle={this.state.sidePanelToggle}
          toggleSidePanel={this.toggleSidePanel}
          widthSize={this.state.windowWidth}
          mobilePOS={this.state.touchMovementDistanceX}
          loading={this.state.panelLoading}
          setLoading={this.setPanelLoading}
        />



        {/* </Grid.Column> */}

        <div className="chatPanel" style={{ marginLeft: 10, marginTop: 10 }}>
          {currentChannel && currentUser && !currentUser.guest &&
          <Messages
            key={currentChannel.name}
            currentChannel={currentChannel}
            currentUser={currentUser}
            isPrivateChannel={isPrivateChannel}
            sidePanelToggle={this.state.sidePanelToggle}
            toggleSidePanel={this.toggleSidePanel}
            metaPanelToggle={this.state.metaPanelToggle}
            toggleMetaPanel={this.toggleMetaPanel}
            panelLoading={this.state.panelLoading}
            setPanelLoading={this.setPanelLoading}
          />
          }
          {currentChannel && currentUser && currentUser.guest &&
          <MessagesLoggedout
            key={currentChannel.name}
            currentChannel={currentChannel}
            currentUser={currentUser}
            isPrivateChannel={isPrivateChannel}
            sidePanelToggle={this.state.sidePanelToggle}
            toggleSidePanel={this.toggleSidePanel}
            metaPanelToggle={this.state.metaPanelToggle}
            toggleMetaPanel={this.toggleMetaPanel}
          />
          }
        </div>

        {/* <div className={this.state.metaPanelToggle ? 'metaPanel is-active' : 'metaPanel'} style={{ marginLeft: 10, paddingTop: 10 }} >
          <MetaPanel
            key={currentChannel && currentChannel.name}
            userPosts={userPosts}
            currentChannel={currentChannel}
            isPrivateChannel={isPrivateChannel}
            metaPanelToggle={this.state.metaPanelToggle}
            handleMetaPanel={this.toggleMetaPanel}
          />
        </div> */}
      </Swipe>
    )
  }
}

// const Chat = ({ currentUser, currentChannel, isPrivateChannel, userPosts, primaryColor, secondaryColor, match }) => {
//   console.log('this is chat', match.params);
//   console.log('currentChannel', currentChannel);

//   let state = {
//     sidePanelToggle: false,
//     metaPanelToggle: false
//   }

//   function toggleSidePanel () {
//     this.setState({
//       sidePanelToggle: !this.state.sidePanelToggle
//     })
//   }

//   return (
//   <div className="app" style={{ background: secondaryColor }}>
//     {/* <ColorPanel
//       key={currentUser && currentUser.name}
//       currentUser={currentUser}
//     /> */}
//     {/* <Grid.Column width={4}> */}
//     <div className={'sidePanel ' + this.state.sidePanelToggle ? 'is-active' : ''}>
//       <SidePanel
//         key={currentUser && currentUser.uid}
//         currentUser={currentUser}
//         primaryColor={primaryColor}
//       />
//       <div className="sidePanelNotch">
//         <Icon
//           onClick={this.toggleSidePanel}
//           name="angle double right"
//           color="black"
//         />
//       </div>
//     </div>

//     {/* </Grid.Column> */}

//     <div className="chatPanel" style={{ marginLeft: 10, marginTop: 10 }}>
//       <Messages
//         key={currentChannel && currentchannel.name}
//         currentChannel={currentChannel}
//         currentUser={currentUser}
//         isPrivateChannel={isPrivateChannel}

//       />
//     </div>

//     <div className="metaPanel" >
//       <MetaPanel
//         key={currentChannel && currentChannel.name}
//         userPosts={userPosts}
//         currentChannel={currentChannel}
//         isPrivateChannel={isPrivateChannel}
//       />
//     </div>
//   </div>
// )};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  isPrivateChannel: state.channel.isPrivateChannel,
  userPosts: state.channel.userPosts,
  primaryColor: state.colors.primaryColor,
  secondaryColor: state.colors.secondaryColor,
});

export default connect(mapStateToProps)(Chat);
