import React from "react";
import firebase from "../../firebase";
import AvatarEditor from "react-avatar-editor";
// prettier-ignore
import { Grid, Header, Icon, Dropdown, Image, Modal, Input, Button } from "semantic-ui-react";

class UserPanel extends React.Component {
  state = {
    user: this.props.currentUser,
    modal: false,

    storageRef: firebase.storage().ref(),
    userRef: firebase.auth().currentUser,
    usersRef: firebase.database().ref("users"),

    metadata: {
      contentType: "image/jpeg",
    },

    previewImage: "",

    form: {
      displayname: "",
      previewImageURL: "",
    },
  };

  openModal = () =>
    this.setState({
      modal: true,
      form: {
        displayName: this.state.user.displayName,
        previewImageURL: this.state.user.photoURL,
      },
    });

  closeModal = () => this.setState({ modal: false });

  dropdownOptions = () => [
    {
      key: "user",
      text: (
        <span>
          Signed in as <strong>{this.state.user.displayName}</strong>
        </span>
      ),
      disabled: true,
    },
    {
      key: "avatar",
      text: <span onClick={this.openModal}>Change Profile</span>,
    },
    {
      key: "signout",
      text: <span onClick={this.handleSignout}>Sign Out</span>,
    },
  ];

  saveProfile = async () => {
    const croppedImage = await this.cropImage(this.avatarEditor);
    const previewImageURL = croppedImage
      ? await this.blobToUrl(croppedImage.blob)
      : this.state.form.previewImageURL;

    this.state.userRef
      .updateProfile({
        displayName: this.state.form.displayName,
        photoURL: previewImageURL,
      })
      .then(() => {
        console.log("profile updated");
        this.closeModal();
      })
      .catch((err) => {
        console.error(err);
      });

    this.state.usersRef
      .child(this.state.user.uid)
      .update({ avatar: previewImageURL })
      .then(() => {
        console.log("User avatar updated");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  blobToUrl = (blob) => {
    const { storageRef, userRef, metadata } = this.state;

    return new Promise((resolve) => {
      storageRef
        .child(`avatars/users/${userRef.uid}`)
        .put(blob, metadata)
        .then((snap) => {
          snap.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        });
    });
  };

  handlePreviewImageURLChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        this.setState({ previewImage: reader.result });
      });
    }
  };

  handleDisplayNameChange = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        displayName: event.target.value,
      },
    });
  };

  cropImage = (avatarEditor) => {
    if (!avatarEditor) return;

    return new Promise((resolve) => {
      avatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        resolve({
          croppedImage: imageUrl,
          blob,
        });
      });
    });
  };

  handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => (window.location.href = "/"));
  };

  render() {
    const { user, modal, previewImage, croppedImage } = this.state;
    const { primaryColor } = this.props;

    return (
      <Grid style={{ background: primaryColor, width: "100%" }}>
        <Grid.Column>
          <Grid.Row style={{ padding: "1.2em", margin: 0 }}>
            {/* App Header */}
            {/* <Header inverted floated="left" as="h2">
              <Icon name="code" />
              <Header.Content>ever.chat</Header.Content>
            </Header> */}

            {/* User Dropdown  */}
            <Header style={{ padding: "0.25em" }} as="h4" inverted>
              <Dropdown
                trigger={
                  <span>
                    {/* <Image src={user.photoURL} spaced="right" avatar /> */}
                    {user.displayName}
                  </span>
                }
                options={this.dropdownOptions()}
              />
            </Header>
          </Grid.Row>

          {/* Change User Avatar Modal   */}
          <Modal
            className="editProfileModal"
            basic
            open={modal}
            onClose={this.closeModal}
          >
            <Modal.Header color="black">My Profile</Modal.Header>
            <Modal.Content>
              <div className="inputLabelWrapper">
                <p className="inputLabel">Your screen name (it can be anything):</p>
                <Input
                  value={this.state.form.displayName}
                  onChange={this.handleDisplayNameChange}
                  fluid
                  type="text"
                  name="displayname"
                />
              </div>

              {/* <div className="inputLabelImageWrapper">
                <p className="inputLabel">Current Profile Image:</p>
                <img
                  className="avatarImage"
                  src={this.state.form.previewImageURL}
                />
              </div>

              <div className="avatarInputWrapper">
                <Input
                  className="avatarInput"
                  onChange={this.handlePreviewImageURLChange}
                  fluid
                  type="file"
                  label="New Avatar"
                  name="previewImage"
                />
              </div> */}

              <Grid centered stackable columns={2}>
                <Grid.Row centered>
                  <Grid.Column className="ui center aligned grid">
                    {previewImage && (
                      <AvatarEditor
                        ref={(node) => (this.avatarEditor = node)}
                        image={previewImage}
                        width={120}
                        height={120}
                        border={50}
                        scale={1.2}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" inverted onClick={this.closeModal}>
                <Icon name="remove" /> Cancel
              </Button>
              <Button color="green" inverted onClick={this.saveProfile}>
                <Icon name="save" /> Save
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Column>
      </Grid>
    );
  }
}

export default UserPanel;
